import React from 'react';
import Axios from 'axios';
import { Button, Form, FormGroup, Input, Spinner } from 'reactstrap';
import API_URL from '../Api/ApiUrl';
import kbcLogo from '../../assets/images/logo_larg.png';
import './Start.css';

class Start extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            userMobile: '',
            userId: '',
            showLoading: false,
            validate: {
                userMobileState: ''
            },
        }
        this.handleInputChange = this.handleInputChange.bind(this);
        this.doRegisterFunction = this.doRegisterFunction.bind(this);
    }

    handleInputChange(eventData) {
        const target = eventData.target;
        const value = target.value;
        const name = target.name;
        this.setState({ [name]: value })
    }


    validateUserMobile(e) {
        const { validate } = this.state;
        if (e.target.value !== '') {
            validate.userMobileState = 'has-success'
        } else {
            validate.userMobileState = 'has-danger'
        }
        this.setState({ validate });
    }

    doRegisterFunction(eventData) {
        const { validate, userMobile } = this.state;
        if (userMobile != null && userMobile.length == 11) {
            eventData.preventDefault();
            this.setState({ showLoading: true })
            Axios({
                method: 'post',
                url: API_URL + 'userRegister',
                timeout: 8000,  // 8 seconds timeout
                headers: { "Content-Type": "application/json" },
                data: {
                    mobile: userMobile
                }
            })
                .then(response => {
                    console.log(userMobile);
                    //console.log(response.data.act_code);
                    this.props.history.push({ pathname: '/activation', state: { user_mobile: this.state.userMobile } })
                })
                .catch(error => console.error(error))
        } else {
            if (userMobile.length < 11 || userMobile.length > 11)
                validate.userMobileState = 'has-danger'
            this.setState({ validate });
        }
    }

    render() {
        return (
            <div id="login">
                <div class="container">
                    <div id="login-row" class="row justify-content-center align-items-center">
                        <div id="login-column" class="col-md-4">
                            <div id="login-box" class="col-md-12">
                                <br/>
                                <img src={kbcLogo} alt="logo" width="100" height="100" />
                                <form id="login-form" class="form">
                                    <div class="form-group">
                                    <label>شماره موبایل خود را وارد نمایید</label><br />
                                        <input type="number" name="userMobile" id="userMobile" maxLength="11" placeholder="09********" class="form-control text-center" 
                                        valid={this.state.validate.userMobileState === 'has-success'}
                                        invalid={this.state.validate.userMobileState === 'has-danger'}
                                        onChange={(e) => {
                                            this.validateUserMobile(e)
                                            this.handleInputChange(e)
                                        }} />   
                                    </div>
                                </form>
                                <div class="form-group">
                                    <input onClick={this.doRegisterFunction} type="submit" name="submit" class="btn btn-info btn-md" value="تایید" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default Start;