import React from 'react';
import Axios from 'axios';
import { Spinner, Button, Form, FormGroup, Input } from 'reactstrap';
import { ToastsContainer, ToastsStore, ToastsContainerPosition } from 'react-toasts';
import API_URL from '../Api/ApiUrl';
import kbcLogo from '../../assets/images/logo_larg.png';
import './Activation.css';

class Activation extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
			userId: 0,
            userMobile: '',
            aCode: 0,
            showLoading: false
        }
        this.handleInputChange = this.handleInputChange.bind(this);
        this.checkActivationFunction = this.checkActivationFunction.bind(this);
    }

    handleInputChange(eventData) {
        const target = eventData.target;
        const value = target.value;
        const name = target.name;
        this.setState({ [name]: value })
    }

    recordLog = (log) => {
        console.log(log);
        Axios({
            url: API_URL + 'recordLog',
            method: 'POST',
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            data: log,
        })
            .then(function (response) {
                console.log(response);
                console.log(response.data);
            })
            .catch(function (err) {
                console.log(err);
            });
    }

    setUserDeviceType = (id) => {
        Axios.put(API_URL + 'setCustomerDeviceType/I/' + id)
            .then(response => {
                console.log(response.data.message);
            }).catch(error => {
                console.log(error);
            });
    }

    checkActivationFunction(eventData) {
        const { aCode } = this.state;
        if (aCode != null && aCode.length == 5) {
            eventData.preventDefault();
            const uMobile = this.props.location.state.user_mobile;
            this.setState({ showLoading: true })
            Axios.get(API_URL + 'checkCustomerCode/' + uMobile + '/' + aCode)
                .then(response => {
                    console.log(response.data.id);
                    this.setState({ userId: response.data.id });
                    const { userId } = this.state;
                    if (userId === 0) {
                        ToastsStore.warning('کد فعال سازی نادرست است');
                    } else {
                        //save to storage
                        let id = userId;
                        let name = response.data.name;
                        let family = response.data.family;
                        let gender = response.data.gender;
                        let nationalCode = response.data.nationalCode;
                        let phoneNumber = response.data.phone;
                        let mobile = response.data.mobile;
                        let invite = response.data.inviteCode;

                        window.localStorage.setItem('_userId', String(id));
                        window.localStorage.setItem('_userName', name);
                        window.localStorage.setItem('_userFamily', family);
                        window.localStorage.setItem('_userGender', String(gender));
                        window.localStorage.setItem('_userNationalCode', nationalCode);
                        window.localStorage.setItem('_userMobile', mobile);
                        window.localStorage.setItem('_fisrtLunch', 'false');
                        window.localStorage.setItem('_profileStatus', 'none');
                        window.localStorage.setItem('_inviteCode', invite);
                        window.localStorage.setItem('_phoneNumber', phoneNumber);

                        //record log
                        const log = new FormData()
                        log.append('event_id', '1');
                        log.append('agent', 'C');
                        log.append('customer_id', id);
                        log.append('seller_id', '0');
                        log.append('request_id', '0');
                        log.append('score', '0');
                        this.recordLog(log);

                        //set device type
                        this.setUserDeviceType(id);
                        this.props.history.push({ pathname: '/home' })
                    }
                }).catch(error => {
                    console.log(error);
                });
        }
    }

    render() {
        return (
            <div id="login">
            <div class="container">
                <div id="login-row" class="row justify-content-center align-items-center">
                    <div id="login-column" class="col-md-4">
                        <div id="login-box" class="col-md-12">
                            <br/>
                            <img src={kbcLogo} alt="logo" width="100" height="100" />
                            <form id="login-form" class="form">
                                <div class="form-group">
                                <label>کد فعال سازی را وارد نمایید</label><br />
                                    <input type="number" name="aCode" id="aCode" onChange={this.handleInputChange} maxLength="5" placeholder="کد فعال سازی" style={{ textAlign: 'center' }} class="form-control text-center" />   
                                </div>
                            </form>
                            <div class="form-group">
                                <input onClick={this.checkActivationFunction} type="submit" name="submit" class="btn btn-info btn-md" value="تایید" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        );
    }
}
export default Activation;